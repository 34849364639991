@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'LemonMilk';
  src: url('fonts/LEMONMILK/LEMONMILK-Regular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'LemonMilkBold';
  src: url('fonts/LEMONMILK/LEMONMILK-Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
}

body {
  font-family: 'Roboto', sans-serif;
}
